
































































import Vue from 'vue'
import Vuex from 'vuex'
// @ts-ignore
import Component from 'vue-class-component'
import { Watch, Prop, Ref } from 'vue-property-decorator'
import Time from '../../functions/Time'
import CdrDuration from '../../components/sessions/CdrDuration.vue'
import GenericTablePaginationJira from '../../components/_base/BcDataTable/GenericTablePaginationJira.vue'
import SessionDetails from '../../components/sessions/SessionDetails.vue'
import PaginationInterface from '../../typings/PaginationInterface'
import { throttle } from 'lodash'
import RequestApi from '../../services/RequestApi'
import moment from 'moment'

@Component({
  components: {
    CdrDuration,
    GenericTablePaginationJira,
    SessionDetails
  },
  filters: {
    filterAuthor (val: any) {
      if (val === 'System Administrator') {
        val = 'Me'
        return val
      }
      return val
    },
    status (val: any) {
      if (val === 'PENDING') {
        return 'To do'
      } else if (val === 'ACCEPTED') {
        return 'Done'
      }
      return 'N/A'
    },
    toKwH (val: number | string) {
      if (val > 0) {
        if (typeof val !== 'number') {
          val = parseInt(val)
        }
        return `${val / 1000}`
      } else {
        return 0
      }
    },
    formatDateTime (val: string) {
      if (val) {
        const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return b
      }
      return ''
    }
  }
})
export default class MyRequests extends Vue {
  @Prop({ type: String, default: '0' }) readonly id!: string
  @Prop({ type: String, default: '' }) readonly dateIn!: string
  @Ref() readonly form!: any
  @Ref() readonly commentEditor!: any

  alignments= [
    'start'
  ]

  itemsBrdcrmb: any = []

  payload = {
    body: ''
  }

  isAdd = false
  isProduct = true
  isLoadComment = false
  loading = false

  dataRequest = {
    ID: '',
    issueKey: '',
    currentStatus: {
      status: '',
      statusDate: {
        friendly: ''
      }
    },
    summary: '',
    orderNumber: '',
    customerOrganisation: [],
    driverLastName: '',
    driverFirstName: '',
    gender: {
      self: '',
      value: '',
      id: ''
    },
    language: {
      self: '',
      value: '',
      id: ''
    },
    contactAddress: '',
    contactCity: [],
    contactEmail: '',
    contactPhone: '',
    vip: {
      self: '',
      value: '',
      id: ''
    },
    chargerType: '',
    mountingType: '',
    installationType: '',
    chargerSubscription: '',
    dynamicLoadBalancing: '',
    requestFieldValues: [],
    requestTypeFields: []
  }

  data: Array<any> = []
  selected = 0
  requests = []
  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    pagecount: 0,
    maxResults: 0,
    startAt: 0,
    isLastPage: false,
    type: 0
  }

  // form error
  error = false
  errorMessages = ''
  errorProvider = false
  errorProviderMessages = ''
  isFormValid= true
  isLoading = false
  isSignInDisabled = false
  isSaveRequest = false
  isSaving = false

  // input rules
  rules= {
    required: (value: any) => (value && Boolean(value)) || 'Required'
  }

  commentsTab: Array<any> = []
  userData: any = {}
  created () {
    this.fetchSessions()
    // this.fetchComments()
  }

  getValue (val: 'String') {
    const requestsTab = this.dataRequest.requestFieldValues
    let isFound = false
    let dta: any = ''
    for (let index = 0; index < requestsTab.length && !isFound; index++) {
      const element: any = requestsTab[index]
      if (element.label === val) {
        dta = element.value
        console.log(dta)
        isFound = true
      }
    }
    return dta
  }

  initBreadCrumb () {
    this.itemsBrdcrmb = [{
      text: 'Requests',
      disabled: false,
      href: 'myRequests',
      to: { name: 'myRequests' },
      last: false
    },
    {
      text: this.dataRequest.ID,
      disabled: true,
      href: 'breadcrumbs_link_1',
      last: true
    }]
  }

  submit () {
    if (this.form.validate()) {
      this.isSaveRequest = true
      this.isSaving = true
      this.addComment()
    }
    /* const editor = this.commentEditor
    if (editor.getContent() !== undefined && editor.getContent().length > 0) {
      this.isSaveRequest = true
      this.isSaving = true
      this.addComment()
    } */
  }

  getFieldId (val: 'String') {
    const requestsTab = this.dataRequest.requestFieldValues
    let isFound = false
    let dta: any = ''
    for (let index = 0; index < requestsTab.length && !isFound; index++) {
      const element: any = requestsTab[index]
      if (element.fieldId === val) {
        if (element.value) {
          dta = element.value
          isFound = true
        }
      }
    }
    return dta
  }

  async addComment () {
    try {
      this.isLoadComment = true
      // const editor = this.commentEditor
      // this.payload.body = editor.getContent()
      const data = await new RequestApi().createComment(this.id, this.payload) as any
      this.isLoadComment = false
      this.resetErrors()
      this.payload.body = ''
      // this.fetchSessions()
      this.fetchComments()
      this.isAdd = false
      // editor.setContent('')
    } catch (err) {
      console.log(err)
    }
  }

  async fetchSessions () {
    try {
      this.loading = true
      const data = await new RequestApi().getCPIssue(this.id) as any
      this.dataRequest = data.data
      this.initBreadCrumb()
      // this.initPagination(data)
      // this.dataRequest = data.result.data
      // console.log(this.dataRequest)
      this.loading = false
    } catch (err) {
    }
  }

  initPagination (dt: any) {
    this.pagination.pagecount = dt.result.data.limit
    this.pagination.total = dt.result.data.size
    this.pagination.maxResults = dt.result.data.limit
    this.pagination.startAt = dt.result.data.start
    this.pagination.isLastPage = dt.result.data.isLastPage
  }

  async fetchComments () {
    try {
      const payload = {
        pagesize: this.pagination.pagesize,
        pagenumber: this.pagination.pagenumber
      }
      const data = await new RequestApi().getComments(this.id, payload) as any
      this.initPagination(data)
      this.commentsTab = data.result.data.values
    } catch (err) {
    }
  }

  calcDiff (start: string, end: string) {
    return Time.durationTime(start, end, 'd[Days] HH[h]mm[m]')
  }

  throttleGetData () {
    return throttle(this.fetchSessions, 500, { leading: true })
  }

  resetErrors () {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('id')
  idOnChanged (val: PaginationInterface) {
    this.fetchSessions()
    this.fetchComments()
  }

  @Watch('pagination', { deep: true })
  paginationOnChanged (val: PaginationInterface, oldVal: PaginationInterface) {
    this.fetchComments()
  }
}
